var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "split-screen-icon__four",
      style: "width:" + _vm.width + "px;height:" + _vm.height + "px;",
    },
    [_c("div", { staticClass: "left" }), _vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "right-top" }),
      _c("div", { staticClass: "right-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }