var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-index__wrapper" },
    [
      _vm.layout == "1*1"
        ? _c(
            "layout-one",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData1: _vm.layoutData1 } },
              "layout-one",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.layout == "2*2"
        ? _c(
            "layout-two",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData2: _vm.layoutData2 } },
              "layout-two",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.layout == "3*3"
        ? _c(
            "layout-three",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData3: _vm.layoutData3 } },
              "layout-three",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.layout == "1+2"
        ? _c(
            "layout-four",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData4: _vm.layoutData4 } },
              "layout-four",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.layout == "1+5"
        ? _c(
            "layout-five",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData5: _vm.layoutData5 } },
              "layout-five",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.layout == "3+4"
        ? _c(
            "layout-six",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData6: _vm.layoutData6 } },
              "layout-six",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.layout == "2+6"
        ? _c(
            "layout-seven",
            _vm._b(
              { ref: _vm.layout, attrs: { layoutData7: _vm.layoutData7 } },
              "layout-seven",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }