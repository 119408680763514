var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout__two" }, [
    _c(
      "div",
      {
        staticClass: "left-top",
        class: { two__active: _vm.activeIndex == 1 },
        on: {
          click: function ($event) {
            return _vm.handleClick(1)
          },
        },
      },
      [
        _vm.isShowVideo1
          ? _c("video-flv", {
              ref: "flvPlayer1",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                videoType: _vm.videoType,
                httpFlv: _vm.httpFlvUrl1,
                videoId: "videoId1",
              },
              on: { errVideo: _vm.errVideo },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "right-top",
        class: { two__active: _vm.activeIndex == 2 },
        on: {
          click: function ($event) {
            return _vm.handleClick(2)
          },
        },
      },
      [
        _vm.isShowVideo2
          ? _c("video-flv", {
              ref: "flvPlayer2",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                videoType: _vm.videoType,
                httpFlv: _vm.httpFlvUrl2,
                videoId: "videoId2",
              },
              on: { errVideo: _vm.errVideo },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("span", { staticClass: "line" }),
    _c(
      "div",
      {
        staticClass: "left-bottom",
        class: { two__active: _vm.activeIndex == 3 },
        on: {
          click: function ($event) {
            return _vm.handleClick(3)
          },
        },
      },
      [
        _vm.isShowVideo3
          ? _c("video-flv", {
              ref: "flvPlayer3",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                videoType: _vm.videoType,
                httpFlv: _vm.httpFlvUrl3,
                videoId: "videoId3",
              },
              on: { errVideo: _vm.errVideo },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "right-bottom",
        class: { two__active: _vm.activeIndex == 4 },
        on: {
          click: function ($event) {
            return _vm.handleClick(4)
          },
        },
      },
      [
        _vm.isShowVideo4
          ? _c("video-flv", {
              ref: "flvPlayer4",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                videoType: _vm.videoType,
                httpFlv: _vm.httpFlvUrl4,
                videoId: "videoId4",
              },
              on: { errVideo: _vm.errVideo },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }