var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "split-screen-icon__six",
      style: "width:" + _vm.width + "px;height:" + _vm.height + "px;",
    },
    [_vm._m(0), _vm._m(1)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "left" }),
      _c("div", { staticClass: "right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "left" }),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "top-left" }),
          _c("div", { staticClass: "top-right" }),
        ]),
        _c("div", { staticClass: "bottom" }, [
          _c("div", { staticClass: "bottom-left" }),
          _c("div", { staticClass: "bottom-right" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }